define("liquid-fire/promise", ["exports", "rsvp"], function (_exports, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Ember is already polyfilling Promise as needed, so just use that.
  var _default = _rsvp.Promise;
  _exports.default = _default;
});